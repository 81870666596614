import styled from '@emotion/styled';
import { Header } from '@robinpowered/dashboard-apps-header';
import { Colors } from '@robinpowered/design-system';
import { AmplitudeContext, useAuthContext } from 'contexts';
import { PeoplePage } from 'pages';
import { useContext } from 'react';
import { Breakpoints } from 'styles';
import { config } from './config';
export const App = () => {
  const { currentOrg } = useAuthContext();
  const { trackEvent } = useContext(AmplitudeContext);

  return (
    <>
      {currentOrg?.slug && (
        <Header
          activeOrgSlug={currentOrg?.slug}
          amplitudeEventHandler={trackEvent}
          appConfig={{
            featureFlagUrl: config.featureFlagUrl,
            sessionCookieName: config.sessionCookieName,
          }}
        />
      )}
      <Container>
        <PeoplePage />
      </Container>
    </>
  );
};

const Container = styled.main`
  background-color: ${Colors.Tan30};
  border-top: 1px solid ${Colors.Tan70};
  @media (min-width: ${Breakpoints.LG}) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
